<template>
  <div>
    <employees-tabale></employees-tabale>
  </div>
</template>

<script>
import EmployeesTabale from '../components/Tables/EmployeesTable.vue'
export default {
components:{
    EmployeesTabale
}
}
</script>

<style>


</style>